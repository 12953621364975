<template>
  <div v-if="renderPage">
    <div class="questionnaire-portal__rtq">
      <div v-if="
        withoutSmallStepData[this.currentStep].component === 'Welcome' &&
        withoutSmallStepData[this.currentStep].active
      ">
        <Welcome :labels="labels" @toggleFromWelcomeToRTQ="nextStep"></Welcome>
      </div>
      <div v-if="
       isRTQ(withoutSmallStepData[this.currentStep].component) &&
        withoutSmallStepData[this.currentStep].active
      ">
        <div class="questionnaire-portal-content-wrapper">
          <Questionnaire :questionnaireId="getQuestionnaireType(withoutSmallStepData[this.currentStep].component)" :store="store"
            :mwc-id="`${parentMwcId}.questionnaireWrapper-Risk`" :event-bus="eventBus" :key="customKey"
            @toggleFromRTQToThankYou="nextStep">
          </Questionnaire>
        </div>
      </div>
      <!-- To be used when working on KYC and EXP implementation ticket -->
      <div v-if="
        withoutSmallStepData[this.currentStep].component ===
        appConstants.KYC10 && withoutSmallStepData[this.currentStep].active
      ">
        <div class="questionnaire-portal-content-wrapper">
          <Questionnaire :questionnaireId="`KYC10`" :store="store" :mwc-id="`${parentMwcId}.questionnaireWrapper-KYC`"
            :event-bus="eventBus" :key="customKey" @toggleFromRTQToThankYou="nextStep">
          </Questionnaire>
        </div>
      </div>
      <div v-if="
        withoutSmallStepData[this.currentStep].component ===
        appConstants.EXP10 && withoutSmallStepData[this.currentStep].active
      ">
        <div class="questionnaire-portal-content-wrapper">
          <Questionnaire :questionnaireId="`EXP10`" :store="store" :mwc-id="`${parentMwcId}.questionnaireWrapper-EXP`"
            :event-bus="eventBus" :key="customKey" @toggleFromRTQToThankYou="nextStep">
          </Questionnaire>
        </div>
      </div>
      <div v-if="
        withoutSmallStepData[this.currentStep].component === 'thankyou' &&
        withoutSmallStepData[this.currentStep].active
      ">
        <thank-you></thank-you>
      </div>
    </div>
  </div>
</template>

<script>
import Questionnaire from "../components/Questionnaire.vue";
import ThankYou from "../components/ThankYou.vue";
import Welcome from "../components/Welcome.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import stepperWithRiskOnly from "../constants/stepperWithRiskOnly.js";
import { updateNotificationStatus } from "../service/notificationApiService";

import stepperWithRiskOnlystepperWithRiskKycAndExp from "../constants/stepperWithRiskKycAndExp.js";
import stepperWithRiskAndExpOnly from "../constants/stepperWithRiskAndExpOnly.js";
import stepperWithRiskAndKycOnly from "../constants/stepperWithRiskAndKycOnly.js";
import AppConstants from "../constants/constants.json";
import labels from "../config/labels.json";
import v4Labels from "../config/v4_labels.json";
import { redirectToErrorPage } from "../utils/redirectToErrorPage";
import { getRandomNumber } from "../helper";

export default {
  name: "RTQ",
  props: {
    eventBus: Object,
    parentMwcId: String,
    store: Object,
  },
  components: {
    Questionnaire,
    ThankYou,
    Welcome,
  },
  data() {
    return {
      currentStep: 0,
      key: 0,
      withoutSmallStepData: stepperWithRiskOnly(AppConstants.FM10S30),
      customKey: 0,
      questionnaireId: AppConstants.FM10S30,
      appConstants: AppConstants,
      languageDropdown: [],
      showDropdown: false,
      renderPage: false,
      questionnaireToBeContinued: null,
      labels: labels,
    };
  },
  created() {
    let hasKyc = false;
    let hasExp = false;
     if(this.notificationDetails.type.includes(this.appConstants.FM25V30)){
      this.withoutSmallStepData = stepperWithRiskOnly(AppConstants.FM25V30);
      this.questionnaireId = this.appConstants.FM25V30;
    }
    if(this.notificationDetails.type.includes(this.appConstants.FM25V40)){
      this.withoutSmallStepData = stepperWithRiskOnly(AppConstants.FM25V40);
      this.questionnaireId = this.appConstants.FM25V40;
      this.labels = v4Labels;
    }
    if(this.notificationDetails.type.includes(this.appConstants.FM10S40)){
      this.withoutSmallStepData = stepperWithRiskOnly(AppConstants.FM10S40);
      this.questionnaireId = this.appConstants.FM10S40;
      this.labels = v4Labels;
    }
    if (this.notificationDetails.type.find(this.typeHasKyc)) {
      hasKyc = true;
    }
    if (this.notificationDetails.type.find(this.typeHasExperience)) {
      hasExp = true;
    }
    if (hasKyc && hasExp) {
      this.withoutSmallStepData = stepperWithRiskOnlystepperWithRiskKycAndExp(this.questionnaireId);
      this.key += getRandomNumber();
    } else if (hasKyc && !hasExp) {
      this.withoutSmallStepData = stepperWithRiskAndKycOnly(this.questionnaireId);
      this.key += getRandomNumber();
    } else if (!hasKyc && hasExp) {
      this.withoutSmallStepData = stepperWithRiskAndExpOnly(this.questionnaireId);
      this.key += getRandomNumber();
    }
  },
  methods: {
    ...mapActions(`productStore`, [`updateCurrentNotificationStatus`]),
    nextStep() {
      this.currentStep += 1;
      this.withoutSmallStepData[this.currentStep - 1].active = false;
      this.withoutSmallStepData[this.currentStep].active = true;
    },
    typeHasKyc(value) {
      return value === AppConstants.KYC10;
    },
    typeHasExperience(value) {
      return value === AppConstants.EXP10;
    },
    checkCurrentNotificationStatus(status) {
      return this.currentNotificationStatus === AppConstants[status];
    },
    findStepFromStepper(step) {
      if (
        step.component === this.questionnaireToBeContinued.type &&
        step.id !== 2
      ) {
        return step;
      }
      return false;
    },
    isRTQ(component) {
      return component === this.appConstants.FM10S30 || component === this.appConstants.FM10S40
          || component === this.appConstants.FM25V30 || component === this.appConstants.FM25V40
    },
    getQuestionnaireType(component) {
      if (component === this.appConstants.FM10S30) {
        return `FM10S30`;
      }
      if (component === this.appConstants.FM10S40) {
        return `FM10S40`;
      }
      if (component === this.appConstants.FM25V30) {
        return `FM25V30`;
      }
      return `FM25V40`;
    }
  },
  async mounted() {
    this.$nextTick(function () {
      this.languageId = this.language;
    });
    this.currentStep = 0;
    this.withoutSmallStepData[this.currentStep].active = true;
    this.questionnaireToBeContinued = this.notificationDetails?.events && this.notificationDetails.events[
      this.notificationDetails.events.length - 1
    ];
    if (this.notificationDetails?.clientId) {
      await this.$store.dispatch(
          "rtqStore/updateFMRTQClientId",
          this.notificationDetails.clientId
      );
    }
    if (this.checkCurrentNotificationStatus('requestCompleted')) {
      redirectToErrorPage(
        this.labels[this.language].surveyCompleted,
        this.notificationDetails.language,
        false,
        this.labels[this.language].submissionSuccessNote
      );
    } else if (
      this.checkCurrentNotificationStatus('requestCreated') ||
      this.checkCurrentNotificationStatus('requestSent')
    ) {
      this.showLoader = false;
      this.renderPage = true;
      const response = await updateNotificationStatus(
        this.notificationDetails.id,
        AppConstants.requestViewed
      );
      if (response?.data?.id) {
        await this.updateCurrentNotificationStatus(AppConstants.requestViewed);
      }
    } else {
      if (this.questionnaireToBeContinued?.type) {
        const advanceToStep =
          this.withoutSmallStepData.find(this.findStepFromStepper);
        this.withoutSmallStepData[this.currentStep].active = false;
        this.currentStep = advanceToStep
          ? advanceToStep.id - 1
          : this.currentStep;
        this.withoutSmallStepData[this.currentStep].active = true;
      }
      this.renderPage = true;
    }
  },
  computed: {
    ...mapState(`authStore`, [`notificationToken`, `token`]),
    ...mapGetters(`authStore`, [`notificationToken`, `token`]),
    ...mapState(`productStore`, [
      `currentNotificationStatus`,
      `language`,
      `notificationDetails`,
    ]),
    ...mapGetters(`productStore`, [
      `currentNotificationStatus`,
      `language`,
      `notificationDetails`,
    ])
  }
};
</script>