<template>
    <div>
        <div class="mwc-portfolio-planner-questionnaire">
            <mwc-fm-questionnaire
                ref="mwcFmQuestionnaire"
                :mwc-id="getMwcIdForSibling(`questionnaire`)"
                :config.prop="mwcQuestionnaireConfig"
                :show-loading-state.prop="true"
                :read-only.prop="readOnly"
                :questionnaire-type.prop="questionnaireType"
                :questionnaire-id.prop="questionnaireId"
                :data-sources.prop="getDataSources"
                :key="key"
                @mwc-questionnaire-answer-change="onQuestionnaireAnswerChange"
                @mwc-questionnaire-reset="onQuestionnaireReset"
                @mwc-get-questionnaire="onGettingQuestionnaire"
            >
            </mwc-fm-questionnaire>
            <div class="mwc-portfolio-planner-questionnaire-button-wrapper">
                <mds-button v-if="isQuestionnaireCompleted()"
                    variation="primary"
                    @click="advanceToNextStep" :loading="isLoading">
                    {{ translate('nextButton') }}
                </mds-button>
            </div>
        </div>
    </div>
</template>

<script>
import { MdsButton } from "@mds/button";
import "mwc-fm-questionnaire";
import MwcVueWrapperMixin from "../mixins/mwc-vue-component-wrapper";
import UsageTrackingMixin from "ec-mwc-component-helper/src/usage-tracking-mixin";

import { mapState, mapGetters, mapActions } from 'vuex';
import { updateNotificationStatus, updateQuestionnaireResponse } from '../service/notificationApiService';
import { generateRiskResponseId, generateKYCResponseId, generateExperienceResponseId } from '../service/questionnaireService';
import AppConstants from '../constants/constants.json';
import labels from '../config/labels.json';
import { getRandomNumber } from '../helper';

export default {
    showLoading: true,
    name: "questionnaire-wrapper",
    mixins: [MwcVueWrapperMixin, UsageTrackingMixin],
    components: {
        MdsButton,
    },
    data() {
        return {
            state: this.store.state,
            counter: 0,
            readOnly: false,
            mwcQuestionnaireConfig: {},
            isResetEnabled: false,
            labels: labels,
            questionnaireType: undefined,
            key: 0,
            isLoading: false,
            showDialog: false
        }
    },
    props: {
        eventBus: {
            type: Object,
        },
        store: {
            type: Object,
        },
        mwcConfig: {
            type: Object,
        },
        questionnaireId: {
            type: String
        }
    },
    watch: {
        questionnaireId(newValue){
            switch(newValue){
                case 'FM10S30':
                    this.questionnaireType = 'risk';
                    break;
                case 'FM25V30':
                    this.questionnaireType = 'risk';
                    break;
                case 'FM10S40':
                  this.questionnaireType = 'risk';
                  break;
                case 'FM25V40':
                  this.questionnaireType = 'risk';
                  break;
                case 'EXP10':
                    this.questionnaireType = 'experience';
                    break;
                case 'KYC10':
                    this.questionnaireType = 'kyc';
                    break;
                default:
                    break;
            }
            this.state.results = [];
            this.state.answers = [];
            this.counter = 0;
            this.isResetEnabled = false;
            this.key +=  getRandomNumber();
        }
    },
    created() {
        window.addEventListener('beforeunload', this.handleClose);
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.handleClose);
    },
    async mounted() {
        this.$root.$emit("hideDropdown");
        if( this.currentNotificationStatus === AppConstants.requestViewed ){
          const response = await updateNotificationStatus(this.notificationDetails.id, AppConstants.requestInProgress, this.questionnaireId);
          if(response && response.data.id) {
              this.updateCurrentNotificationStatus(AppConstants.requestInProgress);
          }
        }
        // Commenting, as it will be required in future
        // this.$nextTick(function() {
        //     this.languageId = this.language;
        //     this.key += getRandomNumber();
        // });
    },
    methods: {
        handleClose(e) {
            e.preventDefault();
            e.returnValue = ``;
        },
        ...mapActions(`productStore`, [
            `updateCurrentNotificationStatus`,
            `updateLanguage`,
            `updateNotificationDetails`
        ]),
        ...mapActions(`authStore`, [
            `updateNotificationToken`
        ]),
        ...mapActions(`rtqStore`, [
            `updateRTQScore`,
        ]),
        async advanceToNextStep() {
            this.isLoading = true;
            if(this.notificationDetails){
                switch(this.questionnaireId) {
                    case AppConstants.FM10S30:
                        await this.generateFMRTQResponseId(AppConstants.FM10S30);
                        break;
                    case AppConstants.FM25V30:
                        await this.generateFMRTQResponseId(AppConstants.FM25V30);
                        break;
                    case AppConstants.FM10S40:
                      await this.generateFMRTQResponseId(AppConstants.FM10S40);
                      break;
                    case AppConstants.FM25V40:
                      await this.generateFMRTQResponseId(AppConstants.FM25V40);
                      break;
                    case AppConstants.KYC10:
                        await this.generateKYCResponseId();
                        break;

                    case AppConstants.EXP10:
                        await this.generateExperienceResponseId();
                        break;

                    default:
                        break;
                }
                this.$emit("toggleFromRTQToThankYou");
            }
            this.isLoading = false;
        },
        async generateFMRTQResponseId(riskQuestionnaireType) {
          const answers = this.state.results;
          const data = {
            clientId: this.fmrtqClientId,
            advisorId: this.notificationDetails.requesterId,
            questionnaire: this.questionnaireId,
            questions: answers,
          };
          const headerParameters = {
                Authorization: `Bearer ${this.token}`,
            }
          const response = await generateRiskResponseId(JSON.stringify(data), headerParameters);
            this.$store.dispatch("rtqStore/updateFMRTQResponseId",response.data.id);
            this.$store.dispatch("rtqStore/updateFMRTQCalculatedScore",response.data.score.calculatedScore);
            if(this.notificationDetails.type.includes(AppConstants.EXP10)){
               await updateNotificationStatus(this.notificationDetails.id, AppConstants.requestInProgress, AppConstants.EXP10);
            }else if(this.notificationDetails.type.includes(AppConstants.KYC10)) {
               await updateNotificationStatus(this.notificationDetails.id, AppConstants.requestInProgress, AppConstants.KYC10);
            } else {
               this.updateCurrentNotificationStatus(AppConstants.requestCompleted);
            }
            await updateQuestionnaireResponse(this.notificationDetails.id, riskQuestionnaireType ,this.fmrtqResponseId);
        },
        async generateExperienceResponseId() {
          const answers = this.state.results;
          const data = {
            clientId: this.fmrtqClientId,
            advisorId: this.notificationDetails.requesterId,
            questionnaire: this.questionnaireId,
            questions: answers,
          };
          const headerParameters = {
                Authorization: `Bearer ${this.token}`,
            }
          const response = await generateExperienceResponseId(JSON.stringify(data), headerParameters);

            if(this.notificationDetails.type.includes(AppConstants.KYC10)){
                await updateNotificationStatus(this.notificationDetails.id, AppConstants.requestInProgress, AppConstants.KYC10);
            } 
            await updateQuestionnaireResponse(this.notificationDetails.id, AppConstants.EXP10, response.data.guid);
        },
        async generateKYCResponseId() {
          const answers = this.state.results;
          const data = {
            clientId: this.fmrtqClientId,
            advisorId: this.notificationDetails.requesterId,
            questionnaire: this.questionnaireId,
            questions: answers,
          };
          const headerParameters = {
                Authorization: `Bearer ${this.token}`,
            }
          const response = await generateKYCResponseId(JSON.stringify(data), headerParameters);
          const responseData = await updateQuestionnaireResponse(this.notificationDetails.id, AppConstants.KYC10, response.data.guid);
          if(responseData) {
              this.updateCurrentNotificationStatus(AppConstants.requestCompleted);
          }
        },
        isQuestionnaireCompleted () {
            switch(this.questionnaireId) {
                case AppConstants.FM25V30:
                    return this.counter === 25;
                    
                case AppConstants.FM10S30:
                    return this.counter === 10;

                case AppConstants.FM25V40:
                  return this.counter === 25;

                case AppConstants.FM10S40:
                  return this.counter === 10;

                case AppConstants.EXP10:
                    return this.counter === 4;

                case AppConstants.KYC10:
                    return this.counter === 5;

                default:
                    return false;
            }
        },
        getEventData (data) {
            return data.detail && data.detail[0] || {};
        },
        onQuestionnaireAnswerChange (event) {
            this.isResetEnabled = true;
            const detail = this.getEventData(event);
            //update the counter when user answer the questions
            this.counter = detail.answers
                .filter(element => element.answers[0] != null && element.answers[0] !== "").length;
            this.state.results = detail.answers;
        },
        onGettingQuestionnaire (event) {
            if (this.checkQuestions) {
                event.detail[0].successFunction();
            }
            // this.state.results = [],
            // this.state.answers = []
        },
        onQuestionnaireReset (event) {
            this.counter = 0;
            this.state.results = this.getEventData(event).answers;
        },
    },
    constants: {
        defaultMwcConfig: {
            type: "mwcFmQuestionnaire",
            settings: {
                scrollOptions: {
                    behavior: "smooth",
                    block: "start"
                }
            }
        },
        dialogBodyData: {
            description: "This action will delete the proposed plan because it was built off of the initial plan.",
            labels: {
                primaryButton: "Yes",
                secondaryButton: "No"
            }
        }
    },
    computed: {
        checkQuestions() {
            return window.mwc.dataManager.dataSources["mwcFmQuestionnaire-questionnaire"]?.fetchInProgress;
        },
        getDataSources() {
            return {
                questionnaire: this.questionnaireId + `-questionnaire`,
                questionnaireSubmit: '',
                score: '',
            }
        },
        ...mapState(`rtqStore`, [`fmrtqClientId`,`fmrtqResponseId`, `calculatedScore`]),
        ...mapGetters(`rtqStore`, [
            `fmrtqClientId`,`fmrtqResponseId`, `calculatedScore`
        ]),
        ...mapState(`productStore`, [`currentNotificationStatus`, `notificationDetails`]),
        ...mapGetters(`productStore`, [
            `currentNotificationStatus`,
            `notificationDetails`
        ]),
        ...mapState(`authStore`, [`notificationToken`,`token`]),
        ...mapGetters(`authStore`, [
            `notificationToken`,`token`
        ]),
    },
}
</script>

<style lang="scss">
@import '~mwc-pp-questionnaire/dist/mwc-pp-questionnaire.min.css';
@import "~mwc-fm-questionnaire/dist/mwc-fm-questionnaire.css";
.mwc-portfolio-planner-questionnaire {
    text-align: left;
    padding-top: 2rem;
    padding-left: 5rem;
}
.mwc-portfolio-planner-questionnaire-button-wrapper {
    max-width: 680px;
    margin: 0 auto;
    text-align: right;
}
</style>
