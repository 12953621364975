<template>
  <div v-if="languageId">
    <div class="img">
    </div>
    <div class="welcome-content">
      <h2 class="welcome-text">{{labels[this.languageId].welcome}} {{clientName}}</h2>
      <div class="message-row">
        <span class="text-1" v-html="getWelcomeMessage"></span>
        <h3>{{labels[this.languageId].welcomeHeader}}</h3>
        <div class="list-row">
          <ul>
            <li>{{labels[this.languageId].descriptionNote1}}</li>
            <li>{{labels[this.languageId].descriptionNote2}}</li>
            <li>{{labels[this.languageId].descriptionNote3}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="next-button-wrapper">
      <mds-button variation="primary" @click="goToQuestionnaire" :loading="isLoading"> {{ labels[this.languageId].takeTheQuestionnaireButton}} </mds-button>
    </div>
  </div>
</template>

<script>
import { MdsButton } from "@mds/button";
import { mapState, mapGetters, mapActions } from "vuex";
import { generateClientId } from '../service/questionnaireService';
import AppConstants from '../constants/constants.json';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';

export default {
  name: "welcome",
  components: {
    MdsButton,
  },
  props:{
    labels: {},
  },
  data: function () {
    return {
      clientName:'',
      languageId: '',
      isLoading: false
    };
  },
  beforeMount() {
    if(!this.notificationDetails){
      redirectToErrorPage(this.labels[AppConstants.defaultLanguage].authenticationError);
    } else {
      this.clientName = this.notificationDetails.recipientName;
    }

    this.$nextTick(function() {
      this.languageId = this.language;
      this.$root.$emit("showDropdown");
    });
  },
  computed: {
    getWelcomeMessage() {
         return this.labels[this.languageId].welcomeMessage;
    },
      ...mapState(`productStore`, [`currentNotificationStatus`, `language`, `notificationDetails`]),
      ...mapGetters(`productStore`, [
          `currentNotificationStatus`, `language`,
          `notificationDetails`
      ]),
      ...mapState(`authStore`, [`notificationToken`, `token`]),
      ...mapGetters(`authStore`, [
          `notificationToken`, `token`
      ]),
      ...mapState(`rtqStore`, [`fmrtqClientId`]),
      ...mapGetters(`rtqStore`, [
          `fmrtqClientId`
      ]),
  },
  methods: {
      ...mapActions(`productStore`, [
        `updateCurrentNotificationStatus`,
      ]),
      ...mapActions(`authStore`, [
          `updateNotificationToken`
      ]),

    async goToQuestionnaire() {
      if(!this.fmrtqClientId) {
        this.isLoading = true;
        await this.generateFMRTQClientId();
      }
      this.$emit("toggleFromWelcomeToRTQ");
      this.isLoading = false;
    },

    async generateFMRTQClientId() { 
      const externalId = JSON.stringify({
          "externalId": `${this.notificationDetails.recipientId}`
      });
      const headerParameters = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.token}`
      };
      const response = await generateClientId(externalId, headerParameters);
      this.$store.dispatch("rtqStore/updateFMRTQClientId", response.data.guid);
    }
  }
}
</script>

<style lang="scss">
.img {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0px;
  top: 0px;
  // object-fit: fill;
  background: linear-gradient(150.17deg, rgba(2, 73, 255, 0.4) 2.36%, rgba(78, 191, 255, 0.4) 99.35%), url(../assets/welcome-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.welcome-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 34%;
  height: 38%;
  left: 57%;
  top: 25%;

  .welcome-text {
    position: inherit;
    width: 437px;
    height: 34px;
    left: 0px;
    top: 0px;

    @include mds-level-3-heading($bold: true);
    color: #1E1E1E;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
  }

  .message-row {
    position: inherit;
    width: 437px;
    height: 194px;
    left: 0px;
    top: 34px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;

    .text-1 {
      position: relative;
      width: 437px;
      height: 182px;
      left: 0px;
      top: 12px;

      @include mds-body-text-l;
      color: #1E1E1E;

      /* Inside auto layout */
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px 0px;
    }

    .list-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    position: inherit;
    width: 437px;
    height: 88px;
    left: 0px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
  }
  }
}

.next-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  position: absolute;
  width: 17%;
  height: 42px;
  left: 57%;
  top: 75%;
}
</style>