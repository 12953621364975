var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderPage
    ? _c("div", [
        _c("div", { staticClass: "questionnaire-portal__rtq" }, [
          _vm.withoutSmallStepData[this.currentStep].component === "Welcome" &&
          _vm.withoutSmallStepData[this.currentStep].active
            ? _c(
                "div",
                [
                  _c("Welcome", {
                    attrs: { labels: _vm.labels },
                    on: { toggleFromWelcomeToRTQ: _vm.nextStep },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isRTQ(_vm.withoutSmallStepData[this.currentStep].component) &&
          _vm.withoutSmallStepData[this.currentStep].active
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "questionnaire-portal-content-wrapper" },
                  [
                    _c("Questionnaire", {
                      key: _vm.customKey,
                      attrs: {
                        questionnaireId: _vm.getQuestionnaireType(
                          _vm.withoutSmallStepData[this.currentStep].component
                        ),
                        store: _vm.store,
                        "mwc-id": `${_vm.parentMwcId}.questionnaireWrapper-Risk`,
                        "event-bus": _vm.eventBus,
                      },
                      on: { toggleFromRTQToThankYou: _vm.nextStep },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.withoutSmallStepData[this.currentStep].component ===
            _vm.appConstants.KYC10 &&
          _vm.withoutSmallStepData[this.currentStep].active
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "questionnaire-portal-content-wrapper" },
                  [
                    _c("Questionnaire", {
                      key: _vm.customKey,
                      attrs: {
                        questionnaireId: `KYC10`,
                        store: _vm.store,
                        "mwc-id": `${_vm.parentMwcId}.questionnaireWrapper-KYC`,
                        "event-bus": _vm.eventBus,
                      },
                      on: { toggleFromRTQToThankYou: _vm.nextStep },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.withoutSmallStepData[this.currentStep].component ===
            _vm.appConstants.EXP10 &&
          _vm.withoutSmallStepData[this.currentStep].active
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "questionnaire-portal-content-wrapper" },
                  [
                    _c("Questionnaire", {
                      key: _vm.customKey,
                      attrs: {
                        questionnaireId: `EXP10`,
                        store: _vm.store,
                        "mwc-id": `${_vm.parentMwcId}.questionnaireWrapper-EXP`,
                        "event-bus": _vm.eventBus,
                      },
                      on: { toggleFromRTQToThankYou: _vm.nextStep },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.withoutSmallStepData[this.currentStep].component === "thankyou" &&
          _vm.withoutSmallStepData[this.currentStep].active
            ? _c("div", [_c("thank-you")], 1)
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }