var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.languageId
    ? _c("div", [
        _c("div", { staticClass: "img" }),
        _c("div", { staticClass: "welcome-content" }, [
          _c("h2", { staticClass: "welcome-text" }, [
            _vm._v(
              _vm._s(_vm.labels[this.languageId].welcome) +
                " " +
                _vm._s(_vm.clientName)
            ),
          ]),
          _c("div", { staticClass: "message-row" }, [
            _c("span", { staticClass: "text-1" }, [
              _vm._v(_vm._s(_vm.labels[this.languageId].welcomeMessage)),
            ]),
            _c("h3", [
              _vm._v(_vm._s(_vm.labels[this.languageId].welcomeHeader)),
            ]),
            _c("div", { staticClass: "list-row" }, [
              _c("ul", [
                _c("li", [
                  _vm._v(_vm._s(_vm.labels[this.languageId].descriptionNote1)),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.labels[this.languageId].descriptionNote2)),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.labels[this.languageId].descriptionNote3)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "next-button-wrapper" },
                [
                  _c(
                    "mds-button",
                    {
                      attrs: { variation: "primary", loading: _vm.isLoading },
                      on: { click: _vm.goToQuestionnaire },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.labels[this.languageId]
                              .takeTheQuestionnaireButton
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }